var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('Spinner', {
    staticClass: "pt-5"
  }) : _vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.benchmark.description))];
      },
      proxy: true
    }])
  }, [[_vm._v(_vm._s(_vm.benchmark.name))]], 2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "CRREM Category",
      "options": _vm.categoryOptions,
      "type": "select",
      "alt": ""
    },
    model: {
      value: _vm.category,
      callback: function callback($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  })], 1), _c('div', {
    staticClass: "col"
  }, [_c('label', {
    attrs: {
      "for": "category"
    }
  }, [_vm._v("Assets")]), _c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "id": "filterType",
      "options": [{
        value: 'asset',
        label: 'Asset'
      } ],
      "type": "select",
      "alt": ""
    },
    model: {
      value: _vm.filterType,
      callback: function callback($$v) {
        _vm.filterType = $$v;
      },
      expression: "filterType"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    attrs: {
      "id": "filter",
      "options": _vm.typeOptions,
      "type": "select2"
    },
    on: {
      "input": _vm.loadData
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1)], 1)])]), _c('apexchart', {
    staticClass: "mb-4",
    attrs: {
      "type": "line",
      "height": "350",
      "options": _vm.pathwayChartOptions,
      "series": _vm.pathwaySeries
    }
  }), _c('p', {
    staticClass: "font-w600 text-center"
  }, [_c('i', {
    staticClass: "fa fa-exclamation-triangle text-warning"
  }), _vm._v(" The below table may be inaccurate if floor areas are not set for all your assets. ")]), _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Benchmark Categories")]), _c('th', [_vm._v("Proportion")]), _c('th', [_vm._v("Electricity (kWh/m"), _c('sup', [_vm._v("2")]), _vm._v(")")]), _c('th', [_vm._v("Gas (kWh/m"), _c('sup', [_vm._v("2")]), _vm._v(")")]), _c('th', [_vm._v("Total (kWh/m"), _c('sup', [_vm._v("2")]), _vm._v(")")])])]), _c('tbody', _vm._l(_vm.categoriesBreakdown, function (cat) {
    return _c('tr', {
      key: cat.name
    }, [_c('td', [_vm._v(_vm._s(cat.name))]), _c('td', [_vm._v(_vm._s(cat.proportion.toFixed(2)) + "%")]), _c('td', [_vm._v(_vm._s(cat.elecIntensity.toFixed(2)))]), _c('td', [_vm._v(_vm._s(cat.gasIntensity.toFixed(2)))]), _c('td', [_vm._v(_vm._s(cat.totalIntensity.toFixed(2)))])]);
  }), 0)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }